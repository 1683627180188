





































































import { api } from "@/api/api";
import { ApiGetShortCurriculumDto } from "@/api/generated/Api";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
export default defineComponent({
  components: { BaseTableFiltered, BaseSheet },
  name: "NewCoursePrerequisitesPage",
  setup(props, { refs }) {
    const store = useStore<StoreState>();
    const router = useRouter();
    const route = useRoute();
    const teachingMethod = ref<string>((route.query.teachingMethod as string) || "");
    const mainCourseId = ref(+route.query.mainCourseId || 0);
    const subCourseId = ref(+route.query.subCourseId || 0);
    const existingCurriculums = ref<ApiGetShortCurriculumDto[]>([]);
    const courseAreaItems = computed(() => {
      if (mainCourseId.value !== 0 && store.state.plans.defaultOptions.courseAreas) {
        const area = store.state.plans.defaultOptions.courseAreas.find((obj: any) => obj.id === mainCourseId.value);
        return area?.children;
      }
      return [];
    });
    const headers = [
      { text: "Id", value: "id" },
      { text: "Navn", value: "name" },
      {
        text: "Medlemsorganisasjon",
        value: "orgName",
      },
      { text: "Hovedområde", value: "mainCourseName" },
      { text: "Kursområde", value: "subCourseName" },
      { text: "Undervisningsform", value: "teachingMethod" },
      {
        text: "Opprettet av",
        value: "insertedByUsername",
      },
      { text: "Handlinger", value: "actions", sortable: false },
    ];
    const updateTable = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }
      await fetchCurriculums();
      router.push({
        query: {
          linkedCourse: route.query.linkedCourse,
          linkedCourseType: route.query.linkedCourseType,
          teachingMethod: teachingMethod.value,
          mainCourseId: mainCourseId.value.toString(),
          subCourseId: subCourseId.value.toString(),
        },
      });
    };

    watch(mainCourseId, (newMainCourseId, oldMainCourseId) => {
      if (newMainCourseId === oldMainCourseId) {
        return;
      }
      subCourseId.value = 0;
      teachingMethod.value = "";
      existingCurriculums.value = [];
      router.push({
        query: {
          linkedCourse: route.query.linkedCourse,
          linkedCourseType: route.query.linkedCourseType,
        },
      });
    });

    watch(subCourseId, (newSubCourseId, oldSubCourseId) => {
      if (newSubCourseId === oldSubCourseId) {
        return;
      }
      teachingMethod.value = "";
      existingCurriculums.value = [];
      router.push({
        query: {
          linkedCourse: route.query.linkedCourse,
          linkedCourseType: route.query.linkedCourseType,
          mainCourseId: mainCourseId.value.toString(),
        },
      });
    });

    onMounted(() => {
      globalLoadingWrapper({ blocking: false }, async () => {
        await Promise.all([
          runStoreAction(store, StoreModules.Courses, StoreActions.CoursesActions.Reset),
          runStoreAction(store, StoreModules.Plans, StoreActions.PlansActions.FetchDefaultOptions),
          runStoreAction(store, StoreModules.SubjectRegister, StoreActions.SubjectRegisterActions.FetchSubjectRegister),
        ]);
      });
    });

    const fetchCurriculums = async () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        if (subCourseId.value !== 0 && mainCourseId.value !== 0) {
          const response = await api.curriculum.getCurriculaAsync({
            MainCourseId: mainCourseId.value,
            SubCourseId: subCourseId.value,
            TeachingMethod: teachingMethod.value,
          });
          existingCurriculums.value = response.data.filter(
            (curriculum: ApiGetShortCurriculumDto) => curriculum.curriculumStatusName !== "Til godkjenning"
          );
        }
      });
    };
    const createNewCourse = (value: any) => {
      router.push({
        name: CourseRouteNames.NewCourse,
        query: {
          ...route.query,
        },
        params: { curriculumId: value.id },
      });
    };
    onMounted(fetchCurriculums);
    return {
      mainCourseId,
      subCourseId,
      teachingMethod,
      courseAreaItems,
      validateNotEmpty,
      headers,
      createNewCourse,
      updateTable,
      existingCurriculums,
      defaultTeachingMethods: computed(() => store.state.plans.defaultOptions.teachingMethods),
      defaultCourseAreas: computed(() => store.state.plans.defaultOptions.courseAreas),
    };
  },
});
